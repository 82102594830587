(function($) {
  $(document).ready(function() {
    $('table thead th.sortable').on('click', function () {
      window.location = $(this).find('a').attr('href');
    });

    $('.calendar').calendar({
      type: 'date'
    });
    $('#range-start').calendar({
      type: 'date',
      endCalendar: $('#range-end'),
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate() + '';
          if (day.length < 2) {
            day = '0' + day;
          }
          var month = (date.getMonth() + 1) + '';
          if (month.length < 2) {
            month = '0' + month;
          }
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('#range-end').calendar({
      type: 'date',
      startCalendar: $('#range-start'),
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate() + '';
          if (day.length < 2) {
            day = '0' + day;
          }
          var month = (date.getMonth() + 1) + '';
          if (month.length < 2) {
            month = '0' + month;
          }
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });

    $('#two-weeks').on('click', function(e) {
      e.preventDefault();

      var now = new Date();
      if (now.getDate() < 16) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth()-1, 15);

        var endDate = new Date();
        endDate.setMonth(endDate.getMonth()-1, getLastDayOfThePreviousMonth());
      } else {
        var startDate = new Date();
        startDate.setDate(15);

        var endDate = new Date();
        endDate.setDate(getLastDayOfCurrentMonth());
      }

      $('#range-start').calendar('set date', startDate);
      $('#range-end').calendar('set date', endDate);
    });

    $('#month').on('click', function(e) {
      e.preventDefault();

      var startDate = new Date();
      startDate.setMonth(startDate.getMonth()-1, 1);

      var endDate = new Date();
      endDate.setMonth(endDate.getMonth()-1, getLastDayOfThePreviousMonth());

      $('#range-start').calendar('set date', startDate);
      $('#range-end').calendar('set date', endDate);
    });
  });

  function getLastDayOfThePreviousMonth() {
    var date = new Date();
    date.setDate(1);
    date.setHours(-1);

    return date.getDate();
  }

  function getLastDayOfCurrentMonth() {
    var date = new Date();
    date.setMonth(date.getMonth()+1, 1);
    date.setHours(-1);

    return date.getDate();
  }
})(jQuery);
